var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Form", {
    attrs: {
      width: 280,
      "submit-title": _vm.submitTitle,
      "proceed-title": _vm.proceedTitle,
      "is-submitting": _vm.isSubmitting,
      "is-proceeding": _vm.isProceeding,
      errors: _vm.errors,
      "errors-presentation-mapper": _vm.errorMapper
    },
    on: { proceed: _vm.onProceed, submit: _vm.onSubmit },
    scopedSlots: _vm._u(
      [
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "header",
                {
                  staticClass: "login-header",
                  attrs: { "data-cy": "login-header" }
                },
                [_vm._t("header")],
                2
              )
            ]
          },
          proxy: true
        },
        {
          key: "body",
          fn: function() {
            return [_c("FormSection", [_vm._t("body")], 2)]
          },
          proxy: true
        },
        {
          key: "submit",
          fn: function() {
            return [_vm._t("submitForm")]
          },
          proxy: true
        },
        {
          key: "procced",
          fn: function() {
            return [_vm._t("proceedForm")]
          },
          proxy: true
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }