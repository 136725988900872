var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "login" },
    [
      _c(
        "section",
        { staticClass: "login__body" },
        [
          _vm.isReleaseInformation ? _c("GitReleaseInformation") : _vm._e(),
          _vm._v(" "),
          _c("FluidBlob"),
          _vm._v(" "),
          _c("div", { staticClass: "login-body__form" }, [_vm._t("form")], 2),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "Footer",
        { attrs: { "space-between": "" } },
        [
          _c(
            "div",
            { staticClass: "login__footer-info" },
            [
              _c("Select", {
                attrs: {
                  "data-cy": "change-language",
                  value: _vm.value,
                  options: _vm.languageOptions,
                  size: _vm.smallSize
                },
                on: { input: _vm.onValueChange }
              }),
              _vm._v(" "),
              _c("LinkButton", {
                attrs: {
                  "data-cy": "help-button",
                  title: _vm.$t(
                    "@Authentication.authentication.components.LoginLayout.help"
                  )
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onHelpClicked.apply(null, arguments)
                  }
                }
              }),
              _vm._v(" "),
              _c("LinkButton", {
                attrs: {
                  "data-cy": "guide-button",
                  title: _vm.$t(
                    "@Authentication.authentication.components.LoginLayout.userGuide"
                  )
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.onUserGuideOpen.apply(null, arguments)
                  }
                }
              }),
              _vm._v(" "),
              _vm._l(_vm.extendedFooterInfo, function(footerInfo, index) {
                return [
                  _c(
                    footerInfo.component,
                    _vm._b(
                      { key: index, tag: "Component" },
                      "Component",
                      footerInfo.props,
                      false
                    )
                  )
                ]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("IconLogoName", {
            staticClass: "login__footer-logo",
            attrs: { width: "85.5", height: "24" },
            nativeOn: {
              click: function($event) {
                return _vm.onWebsiteOpen.apply(null, arguments)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }